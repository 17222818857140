import React from "react";

import { User as FirebaseUser } from "firebase/auth";
import {
  Authenticator,
  buildCollection,
  buildProperty,
  buildSchema,
  FirebaseCMSApp,
  NavigationBuilder,
  NavigationBuilderProps,
} from "@camberi/firecms";

import "typeface-rubik";
import "typeface-space-mono";

// const allowedEmails = process.env.REACT_APP_ADMIN_EMAILS
//   ? process.env.REACT_APP_ADMIN_EMAILS.split(",")
//   : [];

// TODO: Replace with your config
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_PUBLIC_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};


export const tradingRecordSchema = buildSchema({
  name: "Trading record",
  properties: {
    startDate: buildProperty({
      title: "Start Date",
      validation: { required: true },
      dataType: "timestamp",
    }),
    endDate: buildProperty({
      title: "End Date",
      validation: { required: true },
      dataType: "timestamp",
    }),
    pool: buildProperty({
      title: "Pool",
      validation: { required: true },
      dataType: "number",
    }),
    earned: buildProperty({
      title: "Earned",
      validation: { required: true },
      dataType: "number",
    }),
    toPool: buildProperty({
      title: "To pool",
      validation: { required: true },
      dataType: "number",
    }),
    toTrader: buildProperty({
      title: "To trader",
      validation: { required: true },
      dataType: "number",
    }),
    toNFT: buildProperty({
      title: "To NFT",
      validation: { required: true },
      dataType: "number",
    }),
    totalDistributedToLegendary: buildProperty({
      title: "Total distributed legendary",
      validation: { required: true },
      dataType: "number",
    }),
    totalDistributedToEpic: buildProperty({
      title: "Total distributed epic",
      validation: { required: true },
      dataType: "number",
    }),
    totalDistributedToRare: buildProperty({
      title: "Total distributed rare",
      validation: { required: true },
      dataType: "number",
    }),
    totalDistributedToCommon: buildProperty({
      title: "Total distributed common",
      validation: { required: true },
      dataType: "number",
    }),
    totalCommonItemsDistributable: buildProperty({
      title: "Total Common items distributable",
      validation: { required: true },
      dataType: "number",
    }),
    totalLegendaryItemsDistributable: buildProperty({
      title: "Total Legendary items distributable",
      validation: { required: true },
      dataType: "number",
    }),
    totalRareItemsDistributable: buildProperty({
      title: "Total Rare items distributable",
      validation: { required: true },
      dataType: "number",
    }),
    totalEpicItemsDistributable: buildProperty({
      title: "Total Epic items distributable",
      validation: { required: true },
      dataType: "number",
    }),
  },
});

export const blogSchema = buildSchema({
  name: "Blog entry",
  // views: [{
  //     path: "preview",
  //     name: "Preview",
  //     builder: (props) => <BlogEntryPreview {...props}/>
  // }],
  properties: {
    name: buildProperty({
      title: "Name",
      validation: { required: true },
      dataType: "string",
    }),
    header_image: buildProperty({
      title: "Header image",
      dataType: "string",
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "images",
          acceptedFiles: ["image/*"],
          metadata: {
            cacheControl: "max-age=1000000",
          },
        },
      },
    }),
    content: buildProperty({
      title: "Content",
      description:
        "Example of a complex array with multiple properties as children",
      validation: { required: true },
      dataType: "array",
      columnWidth: 400,
      oneOf: {
        typeField: "type", // you can ommit these `typeField` and `valueField` props to use the defaults
        valueField: "value",
        properties: {
          images: buildProperty({
            title: "Images",
            dataType: "array",
            of: buildProperty({
              dataType: "string",
              config: {
                storageMeta: {
                  mediaType: "image",
                  storagePath: "images",
                  acceptedFiles: ["image/*"],
                  metadata: {
                    cacheControl: "max-age=1000000",
                  },
                },
              },
            }),
            description:
              "This fields allows uploading multiple images at once and reordering",
          }),
          text: buildProperty({
            dataType: "string",
            title: "Text",
            config: {
              markdown: true,
            },
          }),
          videoLink: buildProperty({
            dataType: "string",
            title: "Video link",
            validation: { url: true },
          }),
          // products: buildProperty({
          //     title: "Products",
          //     dataType: "array",
          //     of: {
          //         dataType: "reference",
          //         path: "products" // you need to define a valid collection in this path
          //     }
          // })
        },
      },
    }),
    status: buildProperty(({ values }) => ({
      title: "Status",
      validation: { required: true },
      dataType: "string",
      columnWidth: 140,
      config: {
        enumValues: {
          published: {
            label: "Published",
            disabled: !values.header_image,
          },
          draft: "Draft",
        },
      },
    })),
  },
  defaultValues: {
    status: "draft",
  },
});

export default function App() {
  const navigation: NavigationBuilder = async ({
    user,
    authController,
  }: NavigationBuilderProps) => {
    return {
      collections: [
        buildCollection({
          path: "blog",
          schema: blogSchema,
          name: "Blog",
          permissions: ({ authController }) => ({
            edit: true,
            create: true,
            // we have created the roles object in the navigation builder
            delete: authController.extra.roles.includes("admin"),
          }),
        }),
        buildCollection({
          path: "payoutData",
          schema: tradingRecordSchema,
          name: "Trading records",
          permissions: ({ authController }) => ({
            edit: true,
            create: true,
            // we have created the roles object in the navigation builder
            delete: authController.extra.roles.includes("admin"),
          }),
        }),
      ],
    };
  };

  const myAuthenticator: Authenticator<FirebaseUser> = async ({
    user,
    authController,
  }) => {
    // You can throw an error to display a message
    // console.log(user?.email,allowedEmails)
    // if (!(user?.email && allowedEmails.includes(user?.email))) {
    //   throw Error("Access forbidden");
    // }
    // if (user?.email?.includes("flanders")) {
    //   throw Error("Stupid Flanders!");
    // }

    console.log("Allowing access to", user?.email);
    // This is an example of retrieving async data related to the user
    // and storing it in the user extra field.
    const sampleUserData = await Promise.resolve({ roles: ["admin"] });
    authController.setExtra(sampleUserData);
    return true;
  };

  return (
    <FirebaseCMSApp
      name={"Fortiter"}
      authentication={myAuthenticator}
      navigation={navigation}
      firebaseConfig={firebaseConfig}
    />
  );
}
